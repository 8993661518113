import { Flex, Spinner } from '@chakra-ui/react'

const LoadingScreen = ({ height }: { height?: string }) => {
  return (
    <Flex
      width='100%'
      height={height || 'calc(100vh - 100px)'}
      justifyContent='center'
      alignItems='center'
    >
      <Spinner size='xl' />
    </Flex>
  )
}

export default LoadingScreen
