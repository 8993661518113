import axios from 'axios'
import moment from 'moment'
import {
  fetchOverviewTransactionsRequest,
  fetchOverviewTransactionsSuccess,
  fetchOverviewTransactionsFailure,
  fetchTotalsRequest,
  fetchTotalsSuccess,
  fetchTotalsFailure,
} from './overviewSlice'
const WORLDESK_URL = process.env.REACT_APP_WORLDESK_GATEWAY

export const getOverviewTransationsAction = (period: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchOverviewTransactionsRequest())
      const today = moment()

      let startDate: moment.Moment = today.clone()
      switch (period) {
        case 1:
          startDate.subtract(1, 'months')
          break
        case 3:
          startDate.subtract(3, 'months')
          break
        case 6:
          startDate.subtract(6, 'months')
          break
        case 12:
          startDate.subtract(12, 'months')
          break
        default:
          startDate.subtract(6, 'months')
          break
      }

      startDate.startOf('day')
      const res = await axios.post(
        WORLDESK_URL + '/Overview/transactions',
        {
          from_date: startDate.toISOString(),
          to_date: today.toISOString(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      dispatch(fetchOverviewTransactionsSuccess(res.data))
    } catch (err: any) {
      console.log(err.response)
      dispatch(fetchOverviewTransactionsFailure())
    }
  }
}

export const getTotalsAction = (period: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchTotalsRequest())

      const today = moment()

      let startDate: moment.Moment = today.clone()
      switch (period) {
        case 1:
          startDate.subtract(1, 'months')
          break
        case 3:
          startDate.subtract(3, 'months')
          break
        case 6:
          startDate.subtract(6, 'months')
          break
        case 12:
          startDate.subtract(12, 'months')
          break
        default:
          startDate.subtract(6, 'months')
          break
      }

      startDate.startOf('day')

      const url =
        WORLDESK_URL +
        `/Overview/totals?startDate=${startDate.toISOString()}` +
        `&endDate=${today.toISOString()}`

      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch(fetchTotalsSuccess(res.data))
    } catch (err: any) {
      console.log(err.response)
      dispatch(fetchTotalsFailure())
    }
  }
}
