import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getTotalsAction } from 'reduxStore/overview/api'

import LoadingScreen from 'components/baseComponents/loading/LoadingScreen'

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  chart: {
    style: {
      fontFamily: 'Poppins Medium',
    },
  },
})

function Totals({ period }: { period: number }) {
  const dispatch = useDispatch()
  const [chartData, setChartData] = useState<any>({
    general: null,
    payments: null,
    conversions: null,
  })
  const { totals } = useSelector((state: any) => state.overview)

  useEffect(() => {
    dispatch<any>(getTotalsAction(period))
  }, [period, dispatch])

  useEffect(() => {
    if (totals.isFetched && !totals.isFailed) {
      const options = {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
          text: '',
          align: 'left',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          backgroundColor: '#333333',
          style: {
            color: '#CCCDD1', // White text for tooltips
          },
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              color: '#CCCDD1',
              style: {
                textOutline: 'none',
              },
            },
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: [
              '#008CFF',
              '#434348',
              '#90ed7d',
              '#EA773F',
              '#8085e9',
              '#f15c80',
              '#e4d354',
              '#2b908f',
              '#f45b5b',
              '#91e8e1',
            ],
            dataLabels: {
              enabled: true,
              format: '<b>{point.name} - {point.y:,.0f} USD',
            },
          },
        },
        series: [
          {
            name: 'Amount',
            colorByPoint: true,
            data: [
              {
                name: 'Payments',
                y: totals.payments.amount,
              },
              {
                name: 'Conversions',
                y: totals.conversions.amount,
              },
              {
                name: 'Lock Rates',
                y: totals.lock_rates,
              },
            ],
          },
        ],
      }
      const { buy_currency, sell_currency } = totals.conversions
      const currencies = Array.from(
        new Set([...Object.keys(buy_currency), ...Object.keys(sell_currency)])
      )

      // Building series data dynamically based on available currencies
      const buyData = currencies.map(currency => buy_currency[currency] || 0)
      const sellData = currencies.map(currency => sell_currency[currency] || 0)

      const conversionsChartData = {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          backgroundColor: 'transparent', // Setting the background to transparent
        },
        title: {
          text: 'Currency Conversions',
          style: {
            color: '#CCCDD1', // White text for dark mode
          },
        },
        xAxis: {
          categories: currencies, // Dynamic list of currencies
          title: {
            text: 'Currency Type',
            style: {
              color: '#CCCDD1', // White text for axis titles in dark mode
            },
          },
          labels: {
            style: {
              color: '#CCCDD1', // White labels for dark mode
            },
          },
          lineColor: '#CCCDD1', // White line for X axis
          tickColor: '#CCCDD1', // White ticks for X axis
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Amount',
            style: {
              color: '#CCCDD1', // White text for Y axis title
            },
          },
          labels: {
            style: {
              color: '#CCCDD1', // White labels for Y axis in dark mode
            },
          },
          gridLineColor: '#444444', // Darker grid lines to suit dark mode
        },
        series: [
          {
            name: 'Buy Currency',
            data: buyData,
            color: '#556ee6', // Custom color for the Buy Currency series
          },
          {
            name: 'Sell Currency',
            data: sellData,
            color: '#a6b0cf', // Custom color for the Sell Currency series
          },
        ],
        legend: {
          itemStyle: {
            color: '#CCCDD1', // White text for the legend in dark mode
          },
        },
        tooltip: {
          backgroundColor: '#333333', // Dark background for tooltip
          style: {
            color: '#CCCDD1', // White text for tooltips
          },
        },
      }

      const paymentCurrencies = totals.payments.currencies

      // Transforming the data to be suitable for pie chart
      const chartData = Object.entries(paymentCurrencies).map(
        ([currency, amount]) => ({
          name: currency,
          y: amount,
        })
      )

      const paymentsChartData = {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'pie',
          backgroundColor: 'transparent', // Transparent background
        },
        title: {
          text: 'Payments Breakdown by Currency',
          style: {
            color: '#CCCDD1', // White text for dark mode
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          backgroundColor: '#333333', // Dark background for tooltip
          style: {
            color: '#CCCDD1', // White text for tooltip
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y:.0f}', // Show the amount in data labels
              style: {
                color: '#CCCDD1', // White labels for data points in dark mode
              },
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'Currencies',
            colorByPoint: true,
            data: chartData, // Dynamic data for the pie chart
          },
        ],
        legend: {
          itemStyle: {
            color: '#CCCDD1', // White text for the legend in dark mode
          },
        },
      }

      setChartData({
        general: options,
        payments: paymentsChartData,
        conversions: conversionsChartData,
        lock_rates: null,
      })
    }
  }, [totals])

  const getTotals = () => {
    if (totals.isFetched && !totals.isFailed && chartData) {
      return (
        <Flex>
          <Box flex='1'>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartData.general}
            />
          </Box>
          <Box flex='1'>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartData.payments}
            />
          </Box>
          <Box flex='1'>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartData.conversions}
            />
          </Box>
        </Flex>
      )
    }
    if (totals.isFailed) {
      return <Box m='20px'>Failed to load totals</Box>
    }
    return <LoadingScreen />
  }

  return <Box>{getTotals()}</Box>
}

export default Totals
