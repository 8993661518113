import {
  Box,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { MenuItems } from './MenuItems'
import { signOutAction } from 'reduxStore/identity/api'
import { NavLink } from 'react-router-dom'
import { GrLogout } from 'react-icons/gr'
import { FaCircleUser } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'

const MainMenu = () => {
  const dispatch = useDispatch()
  return (
    <Box bgColor='topNavBg'>
      <Flex
        justifyContent='space-between'
        maxW='1750px'
        m='auto'
        p='15px'
        bgColor='topNavBg'
      >
        <Flex alignItems='center' color='menuItemColor'>
          {MenuItems.map(item => {
            return (
              <NavLink
                to={item.link}
                key={item.id}
                className={({ isActive }) =>
                  isActive ? 'active-menu-item' : undefined
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <Icon as={item.icon} color='menuItemColor' w='16px' h='auto' />
                <Box p='0px 10px'>{item.text}</Box>
              </NavLink>
            )
          })}
        </Flex>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaCircleUser />}
              variant='outline'
            />
            <MenuList>
              <MenuGroup>
                <MenuItem>My Account</MenuItem>
                <MenuItem>Payments </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuItem>Docs</MenuItem>
                <MenuItem>FAQ</MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuItem
                  onClick={() => dispatch<any>(signOutAction())}
                  icon={<GrLogout />}
                >
                  Logout
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  )
}

export default MainMenu
