import { createSlice } from '@reduxjs/toolkit'
import getExpirationTime from 'utils/getExpirationTime'
import setAuthToken from 'utils/setAuthToken'

const setItems = (payload: any) => {
  localStorage.setItem('token', payload.access_token)
  localStorage.setItem(
    'expiration_time',
    getExpirationTime(payload.access_token)
  )
  setAuthToken(payload.access_token)
}

const clearItems = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('expiration_time')
  localStorage.removeItem('last_path')
  localStorage.removeItem('last_search')
  localStorage.removeItem('previous_path')
  setAuthToken('')
}

export const identitySlice = createSlice({
  name: 'identity',
  initialState: {
    isAuthenticated: false,
    isAuthenticating: false,
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    scope: '',
    token_type: '',
    error: {
      message: '',
    },
    profile: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      data: {
        email: '',
        phone_number: '',
        sub: '',
      },
    },
  },
  reducers: {
    loginRequest: state => {
      state.isAuthenticating = true
      state.error.message = ''
    },
    loginSuccess: (state, action) => {
      setItems(action.payload)
      state.access_token = action.payload.access_token
      state.expires_in = action.payload.expires_in
      state.refresh_token = action.payload.refresh_token
      state.scope = action.payload.scope
      state.token_type = action.payload.token_type
      state.isAuthenticated = true
      state.isAuthenticating = false
      state.error.message = ''
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.error.message = action.payload
    },
    setAuthenticatedAction: state => {
      state.isAuthenticated = true
    },
    fetchProfileRequest: (state: any) => {
      state.profile.isFetching = true
      state.profile.isFetched = false
      state.profile.isFailed = false
    },
    fetchProfileSuccess: (state: any, action: any) => {
      state.profile.isFetching = false
      state.profile.isFetched = true
      state.profile.isFailed = false
      state.profile.data = action.payload
    },
    fetchProfileFailure: (state: any) => {
      state.profile.isFetching = false
      state.profile.isFetched = true
      state.profile.isFailed = true
    },
    logoutAction: state => {
      clearItems()
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.access_token = ''
      state.expires_in = 0
      state.refresh_token = ''
      state.scope = ''
      state.token_type = ''
      state.error.message = ''

      state.profile.isFetching = false
      state.profile.isFetched = false
      state.profile.isFailed = false
      state.profile.data.email = ''
      state.profile.data.phone_number = ''
      state.profile.data.sub = ''
    },
  },
})

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
  setAuthenticatedAction,
  logoutAction,
} = identitySlice.actions

export default identitySlice.reducer
