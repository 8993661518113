import { Select, FormControl } from '@chakra-ui/react'

const PeriodSelect = ({
  period,
  onChange,
}: {
  period: number
  onChange: any
}) => {
  return (
    <FormControl w={'140px'}>
      <Select
        onChange={e => onChange(Number(e.target.value))}
        value={period}
        id='overview-select-period'
        fontSize='xs'
        textTransform='uppercase'
        h='buttonHeight'
        bgColor='transparent'
        borderColor='rgba(255, 255, 255, 0.16)'
      >
        <option value={1}>1 Month</option>
        <option value={3}>3 Months</option>
        <option value={6}>6 Months</option>
        <option value={12}>12 Months</option>
      </Select>
    </FormControl>
  )
}

export default PeriodSelect
