import React from 'react'
import { Box } from '@chakra-ui/react'
import MainMenu from './mainMenu/MainMenu'

function MainLayout({ children }: { children: React.ReactNode }) {
  document.title = 'Worldesk Backoffice'
  return (
    <Box>
      <MainMenu />
      <Box maxW='1750px' m='auto' p='15px'>
        {children}
      </Box>
    </Box>
  )
}

export default MainLayout
