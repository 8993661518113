import { useState, useEffect, lazy, Suspense } from 'react'
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
import LoadingScreen from 'components/baseComponents/loading/LoadingScreen'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import Login from 'components/identity/Login'
import Dashboard from 'components/dashboard/Dashboard'
import MainLayout from 'components/MainLayout'
import themeAuth from 'theme/themeAuth'
import isTokenValid from './utils/isTokenValid'
import setAuthToken from './utils/setAuthToken'
import { setAuthenticatedAction } from 'reduxStore/identity/identitySlice'
import './App.css'
import { useDispatch } from 'react-redux'
import { getUserProfileAction } from 'reduxStore/identity/api'
import { useSelector } from 'react-redux'
const ChangePassword = lazy(() => import('components/identity/ChangePassword'))
const Companies = lazy(() => import('components/companies/Companies'))
const Company = lazy(() => import('components/companies/Company'))
const Users = lazy(() => import('components/users/Users'))
const User = lazy(() => import('components/users/User'))
const Payments = lazy(() => import('components/companies/Payments'))
const Conversions = lazy(() => import('components/companies/Conversions'))
const Lockrates = lazy(() => import('components/companies/Lockrates'))

function PrivateRoutes() {
  const { isAuthenticated } = useSelector((state: any) => state.identity)
  const location = useLocation()

  useEffect(() => {
    localStorage.setItem('last_path', location.pathname)
    localStorage.setItem('last_search', location.search)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (isAuthenticated) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    )
  } else {
    return <Navigate to={'/login'} />
  }
}

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [currentTheme, setCurrentTheme] = useState(themeAuth)

  useEffect(() => {
    if (localStorage.token && isTokenValid()) {
      setAuthToken(localStorage.token)
      dispatch(setAuthenticatedAction())
      dispatch<any>(getUserProfileAction())
    } else {
      // dispatch(logout())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (document.querySelector('#main-scrollarea')) {
      const elem = document.querySelector('#main-scrollarea > div')
      if (elem) {
        elem.scrollTo(0, 0)
      }
    }
    if (
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/verifyemail' ||
      location.pathname === '/verifyphone' ||
      location.pathname === '/forgotpassword' ||
      location.pathname === '/create-company' ||
      location.pathname === '/quickbooksdisconnect' ||
      location.pathname === '/profile/resetPassword' ||
      location.pathname === '/quickbooksreconnect'
    ) {
      setCurrentTheme(themeAuth)
    }
  }, [location])
  return (
    <>
      <ColorModeScript
        initialColorMode={currentTheme.config.initialColorMode}
      />
      <ChakraProvider theme={currentTheme} resetCSS={true}>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path='/' element={<Dashboard />} />
              <Route
                path='/settings/change-password'
                element={<ChangePassword />}
              />
              <Route path='/companies' element={<Companies />} />
              <Route path='/companies/:id' element={<Company />} />
              <Route path='/companies/:id/payments' element={<Payments />} />
              <Route
                path='/companies/:id/conversions'
                element={<Conversions />}
              />
              <Route path='/companies/:id/lock-rates' element={<Lockrates />} />
              <Route path='/users' element={<Users />} />
              <Route path='/users/:id' element={<User />} />
              {/* etc */}
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </Suspense>
      </ChakraProvider>
    </>
  )
}

export default App
