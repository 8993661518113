export const formatNumber = (num: number | string) => {
  if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  else if (num === 0 || num === '0') return '0'
  else return ''
}

export const formatNumber2 = (num: any) => {
  let decimal = countDecimals(num)
  return parseFloat(parseFloat(num).toFixed(decimal)).toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: decimal,
  })
}

export const countDecimals = function (value: number) {
  if (value && value % 1 !== 0) return value.toString().split('.')[1].length
  return 0
}
