import {
  BiHomeCircle,
  BiBuildings,
  BiUser,
  BiCheckCircle,
} from 'react-icons/bi'

export const MenuItems = [
  {
    text: 'Overview',
    link: `/`,
    id: 'main-menu-overview',
    name: 'overview',
    icon: BiHomeCircle,
  },
  {
    text: 'Companies',
    link: `/companies`,
    id: 'main-menu-companies',
    name: 'companies',
    icon: BiBuildings,
  },
  {
    text: 'Users',
    link: `/users`,
    id: 'main-menu-users',
    name: 'users',
    icon: BiUser,
  },
  {
    text: 'Verify Corpay Account',
    link: `/settings/change-password`,
    id: 'main-menu-change-password',
    name: 'change-password',
    icon: BiCheckCircle,
  },
]
