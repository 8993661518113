import axios from 'axios'
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
  logoutAction,
} from './identitySlice'
const WORLDESK_URL = 'https://auth.dev.worldesk.net'
const clientId = process.env.REACT_APP_WORLDESK_CLIENT_ID || ''
const clientSecret = process.env.REACT_APP_WORLDESK_CLIENT_SECRET || ''

export const loginAction = (email: string, password: string) => {
  const params = new URLSearchParams()

  params.append('username', email)
  params.append('password', password)
  params.append('client_id', clientId)
  params.append('client_secret', clientSecret)
  params.append('grant_type', 'password')

  return async (dispatch: any) => {
    try {
      dispatch(loginRequest())
      const res = await axios.post(WORLDESK_URL + '/connect/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      dispatch(loginSuccess(res.data))
      dispatch(getUserProfileAction())
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        if (typeof err.response.data === 'string') {
          dispatch(
            loginFailure(
              'We are unable to log you in, please try again in few minutes or contact Worldesk team'
            )
          )
        }
        if (err.response.data.error) {
          if (err.response.data.error_description) {
            dispatch(loginFailure(err.response.data.error_description))
          }
        }
      } else {
        dispatch(loginFailure('Login failed. Please contact administrator'))
      }
    }
  }
}

export const getUserProfileAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchProfileRequest())
      const res = await axios.get(WORLDESK_URL + '/identity/api/Profile', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch(fetchProfileSuccess(res.data))
    } catch (err: any) {
      console.log(err.response)
      dispatch(fetchProfileFailure())
    }
  }
}

export const signOutAction = () => async (dispatch: any) => {
  try {
    await axios.post(WORLDESK_URL + '/identity/api/account/logout', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(logoutAction())
  } catch (err: any) {
    console.log(err.response)
    dispatch(logoutAction())
  }
}
