import { createSlice } from '@reduxjs/toolkit'

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    filters: {
      name: '',
      sort_order: 'ascending',
      sort_by: 'name',
      take: 10,
      page: 1,
      currency: '',
    },
    companies: {
      page_number: 1,
      total_pages: 1,
      isFetching: false,
      isFetched: false,
      isFailed: false,
      errorMessage: '',
      items: [],
    },
    companyUsers: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    companyDetails: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      editFetching: false,
      editFetched: false,
      editFailed: false,
      data: {
        details: null,
        conversions: [],
        lock_rates: [],
        payments: [],
      },
    },
    payments: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      page_number: 1,
      total_pages: 1,
      filters: {
        currency: '',
        min_amount: '',
        max_amount: '',
        start_date: '',
        end_date: '',
        scheduled_start_date: '',
        scheduled_end_date: '',
        sort_order: 'descending',
        state: [],
        take: 10,
        page: 1,
        sort_by: 'createdAt',
      },
      items: [],
    },
    conversions: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      page_number: 1,
      total_pages: 1,
      filters: {
        sell_currency: '',
        buy_currency: '',
        min_amount: '',
        max_amount: '',
        start_date: '',
        end_date: '',
        sort_order: 'descending',
        state: [],
        take: 10,
        page: 1,
        sort_by: 'createdAt',
      },
      items: [],
    },
    lockrates: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      page_number: 1,
      total_pages: 1,
      filters: {
        sell_currency: '',
        buy_currency: '',
        min_amount: '',
        max_amount: '',
        start_date: '',
        end_date: '',
        sort_order: 'descending',
        state: [],
        take: 10,
        page: 1,
        sort_by: 'createdAt',
      },
      items: [],
    },
    payment: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      data: null,
    },
    conversion: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      data: null,
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setMetricsFilters: (state: any, action) => {
      state[action.payload.type].filters = action.payload.data
    },
    getCompaniesRequest: state => {
      state.companies.isFetching = true
      state.companies.isFetched = false
      state.companies.isFailed = false
    },
    getCompaniesSuccess: (state, action) => {
      state.companies.isFetching = false
      state.companies.isFetched = true
      state.companies.isFailed = false
      state.companies.items = action.payload.data
      state.companies.page_number = action.payload.page_number
      state.companies.total_pages = action.payload.total_pages
    },
    getCompaniesFailure: (state, action) => {
      state.companies.isFetching = false
      state.companies.isFetched = true
      state.companies.isFailed = true
      state.companies.errorMessage =
        action.payload === 403 ? 'Access Denied' : 'Failed to load companies'
    },
    getCompanyDetailsRequest: state => {
      state.companyDetails.isFetching = true
      state.companyDetails.isFetched = false
      state.companyDetails.isFailed = false
      state.companies.errorMessage = ''
    },
    getCompanyDetailsSuccess: (state, action) => {
      state.companyDetails.isFetching = false
      state.companyDetails.isFetched = true
      state.companyDetails.isFailed = false
      state.companyDetails.data = action.payload
    },
    getCompanyDetailsFailure: state => {
      state.companyDetails.isFetching = false
      state.companyDetails.isFetched = true
      state.companyDetails.isFailed = true
    },
    editCompanyRequest: state => {
      state.companyDetails.editFetching = true
      state.companyDetails.editFetched = false
      state.companyDetails.editFailed = false
    },
    editCompanySuccess: (state, action) => {
      state.companyDetails.editFetching = false
      state.companyDetails.editFetched = true
      state.companyDetails.editFailed = false
      state.companyDetails.data.details = action.payload
    },
    editCompanyFailure: state => {
      state.companyDetails.editFetching = false
      state.companyDetails.editFetched = true
      state.companyDetails.editFailed = true
    },
    getCompanyUsersRequest: state => {
      state.companyUsers.isFetching = true
      state.companyUsers.isFetched = false
      state.companyUsers.isFailed = false
    },
    getCompanyUsersSuccess: (state, action) => {
      state.companyUsers.isFetching = false
      state.companyUsers.isFetched = true
      state.companyUsers.isFailed = false
      state.companyUsers.items = action.payload
    },
    getCompanyUsersFailure: state => {
      state.companyUsers.isFetching = false
      state.companyUsers.isFetched = true
      state.companyUsers.isFailed = true
    },
    getCompanyMetricsRequest: (state: any, action) => {
      state[action.payload.type].isFetching = true
      state[action.payload.type].isFetched = false
      state[action.payload.type].isFailed = false
    },
    getCompanyMetricsSuccess: (state: any, action) => {
      state[action.payload.type].isFetching = false
      state[action.payload.type].isFetched = true
      state[action.payload.type].isFailed = false
      state[action.payload.type].items = action.payload.data.data
      state[action.payload.type].page_number = action.payload.data.page_number
      state[action.payload.type].total_pages = action.payload.data.total_pages
    },
    getCompanyMetricsFailure: (state: any, action) => {
      state[action.payload.type].isFetching = false
      state[action.payload.type].isFetched = true
      state[action.payload.type].isFailed = true
    },
    getCompanySingleMetricsRequest: (state: any, action) => {
      state[action.payload.type].isFetching = true

      state[action.payload.type].isFailed = false
    },
    getCompanySingleMetricsSuccess: (state: any, action) => {
      state[action.payload.type].isFetching = false
      state[action.payload.type].isFetched = true
      state[action.payload.type].isFailed = false
      state[action.payload.type].data = action.payload.data
    },
    getCompanySingleMetricsFailure: (state: any, action) => {
      state[action.payload.type].isFetching = false
      state[action.payload.type].isFetched = true
      state[action.payload.type].isFailed = true
    },
  },
})

export const {
  setFilters,
  setMetricsFilters,
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesFailure,
  getCompanyDetailsRequest,
  getCompanyDetailsSuccess,
  getCompanyDetailsFailure,
  editCompanyRequest,
  editCompanySuccess,
  editCompanyFailure,
  getCompanyUsersRequest,
  getCompanyUsersSuccess,
  getCompanyUsersFailure,
  getCompanyMetricsRequest,
  getCompanyMetricsSuccess,
  getCompanyMetricsFailure,
  getCompanySingleMetricsRequest,
  getCompanySingleMetricsSuccess,
  getCompanySingleMetricsFailure,
} = companySlice.actions

export default companySlice.reducer
