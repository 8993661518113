import { useState } from 'react'

import {
  Button,
  FormLabel,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
  Tooltip,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react'

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { FaInfo } from 'react-icons/fa'

interface TextInputI {
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  isReadOnly?: boolean
  value: string
  name: string
  id: string
  isRequired?: boolean
  autoComplete?: string
  type?: string
  isInvalid?: boolean
  placeholder?: string
  horizontal?: boolean
  bgColor?: string
  labelColor?: string
  labelWidth?: string
  border?: string
  invalidFieldError?: string
}

function TextInput({
  label,
  onChange,
  onBlur,
  disabled,
  isReadOnly,
  value,
  name,
  id,
  isRequired,
  autoComplete,
  type = 'text',
  isInvalid,
  placeholder,
  horizontal,
  bgColor,
  labelColor,
  labelWidth,
  border,
  invalidFieldError,
}: TextInputI) {
  const [showPassword, setShowPassword] = useState(false)
  const { colorMode } = useColorMode()
  const getLabel = () => {
    if (horizontal) {
      return (
        <FormLabel
          fontSize='sm'
          fontWeight='normal'
          color={labelColor || 'brand.200'}
          textTransform='uppercase'
          mb='2'
          ml='0'
          flexShrink='0'
          htmlFor={id}
          width={labelWidth || 'auto'}
        >
          {label}
        </FormLabel>
      )
    }
    return (
      <FormLabel
        display='flex'
        alignItems='center'
        position='relative'
        fontSize='sm'
        fontWeight='normal'
        color={
          labelColor ||
          (colorMode === 'dark' ? 'inputLabelColor' : 'lightGrayColor')
        }
        htmlFor={id}
        mb='2'
      >
        <Flex alignItems='center'>
          {label}
          {isInvalid && invalidFieldError && (
            <FormErrorMessage m='0' position='absolute' left='calc(100% + 3px)'>
              <Tooltip
                label={invalidFieldError}
                bg={colorMode === 'dark' ? 'white' : 'lightGrayColor'}
              >
                <IconButton
                  icon={<FaInfo />}
                  minW='8'
                  h='8'
                  isRound
                  variant='ghost'
                  fontSize='xs'
                  color='red.300'
                  lineHeight='1'
                  aria-label=''
                ></IconButton>
              </Tooltip>
            </FormErrorMessage>
          )}
        </Flex>
      </FormLabel>
    )
  }

  const getBgColor = () => {
    if (horizontal) {
      if (colorMode === 'dark') {
        return 'secondaryBg'
      } else {
        return 'grayColor'
      }
    } else {
      return bgColor || 'secondaryBg'
    }
  }

  return (
    <Flex
      flexDirection={horizontal ? { sm: 'column', md: 'row' } : 'column'}
      alignItems={
        horizontal ? { sm: 'flex-start', md: 'center' } : 'flex-start'
      }
    >
      {label ? getLabel() : null}
      <InputGroup size='md'>
        <Input
          color={
            disabled
              ? colorMode === 'dark'
                ? 'gray.500'
                : 'gray.800'
              : colorMode === 'dark'
              ? 'white'
              : 'grayTextColor'
          }
          bg={getBgColor()}
          borderColor='borderColorTranslucent'
          borderRadius='4px'
          fontSize='sm'
          size='lg'
          w='100%'
          maxW='100%'
          h='inputHeight'
          type={showPassword ? 'text' : type}
          px='3'
          autoComplete={autoComplete}
          id={id}
          data-testid={id}
          name={name}
          value={value}
          onChange={e => onChange(e)}
          onBlur={e => (onBlur ? onBlur(e) : null)}
          isRequired={isRequired}
          isDisabled={disabled}
          placeholder={placeholder}
          isInvalid={isInvalid}
          isReadOnly={isReadOnly}
        />
        {type === 'password' && (
          <InputRightElement width='4.5rem' top='0' h='100%'>
            <Button
              h='100%'
              size='sm'
              onClick={() => setShowPassword(!showPassword)}
              variant='ghost'
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
}

export default TextInput
