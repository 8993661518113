/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*eslint-disable*/
import { Box, Flex, Link, List, ListItem, Text } from '@chakra-ui/react'

export default function AuthFooter(props: any) {
  const textColor = 'gray.400'
  return (
    <Box>
      <Flex
        alignItems={{
          base: 'center',
        }}
        justifyContent='center'
        fontSize='sm'
      >
        <Text
          color={textColor}
          textAlign={{
            base: 'center',
          }}
        >
          &copy;{' '}
          <Link
            target='_blank'
            color='inherit'
            href='http://mosaicafx.com/'
            id='link-mosaica'
          >
            Mosaica inc
          </Link>{' '}
          {new Date().getFullYear()}
        </Text>
      </Flex>
    </Box>
  )
}
