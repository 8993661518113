export const displayDateFormat = () => {
  const format = localStorage.getItem('date_time_format')
  if (format) {
    if (format === 'US') {
      return 'MM/DD/YYYY'
    } else if (format === 'EU') {
      return 'DD/MM/YYYY'
    } else {
      return 'MM/DD/YYYY'
    }
  }
  return 'MM/DD/YYYY'
}
