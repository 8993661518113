import { configureStore } from '@reduxjs/toolkit'
import identitySlice from './identity/identitySlice'
import companySlice from './company/companySlice'
import usersSlice from './users/usersSlice'
import overviewSlice from './overview/overviewSlice'

export default configureStore({
  reducer: {
    identity: identitySlice,
    company: companySlice,
    users: usersSlice,
    overview: overviewSlice,
  },
})
