import { Box } from '@chakra-ui/react'
import React from 'react'
import { getCurrencySymbol } from 'utils/currencies'

function AmountCell({
  amount,
  currency,
  code,
}: {
  amount: string
  currency?: string
  code?: string
}) {
  return (
    <>
      {currency ? (
        <Box as='span' fontSize='12px'>
          {getCurrencySymbol(currency)}
        </Box>
      ) : null}
      {Boolean(amount) && (
        <Box as='span' m='0px 5px' ml='1px' data-testid={`${code}-amount`}>
          {amount}
        </Box>
      )}
      {currency ? <Box as='span'>{currency}</Box> : null}
    </>
  )
}

export default AmountCell
