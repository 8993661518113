import { createSlice } from '@reduxjs/toolkit'

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    filters: {
      email: '',
      sort_order: 'ascending',
      sort_by: 'email',
      take: 10,
      page: 1,
    },
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: [],
    errorMessage: '',
    page_number: 1,
    total_pages: 1,
    userDetails: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      editFetching: false,
      editFetched: false,
      editFailed: false,
      data: null,
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    getUsersRequest: state => {
      state.isFetching = true
      state.isFetched = false
      state.isFailed = false
      state.errorMessage = ''
    },
    getUsersSuccess: (state, action) => {
      state.isFetching = false
      state.isFetched = true
      state.isFailed = false
      state.items = action.payload.data
      state.page_number = action.payload.page_number
      state.total_pages = action.payload.total_pages
    },
    getUsersFailure: (state, action) => {
      state.isFetching = false
      state.isFetched = true
      state.isFailed = true
      state.errorMessage =
        action.payload === 403 ? 'Access Denied' : 'Failed to load users'
    },
    getUserDetailsRequest: state => {
      state.userDetails.isFetching = true
      state.userDetails.isFetched = false
      state.userDetails.isFailed = false
    },
    getUserDetailsSuccess: (state, action) => {
      state.userDetails.isFetching = false
      state.userDetails.isFetched = true
      state.userDetails.isFailed = false
      state.userDetails.data = action.payload
    },
    getUserDetailsFailure: state => {
      state.userDetails.isFetching = false
      state.userDetails.isFetched = true
      state.userDetails.isFailed = true
    },
    editUserRequest: state => {
      state.userDetails.editFetching = true
      state.userDetails.editFetched = false
      state.userDetails.editFailed = false
    },
    editUserSuccess: (state, action) => {
      state.userDetails.editFetching = false
      state.userDetails.editFetched = true
      state.userDetails.editFailed = false
      state.userDetails.data = action.payload
    },
    editUserFailure: state => {
      state.userDetails.editFetching = false
      state.userDetails.editFetched = true
      state.userDetails.editFailed = true
    },
  },
})

export const {
  setFilters,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
} = usersSlice.actions

export default usersSlice.reducer
