import { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import LoadingScreen from '../baseComponents/loading/LoadingScreen'
import { validateEmail } from '../../utils/validateEmail'
// import { getV3Key, getV2Key } from '../../utils/recaptchaKey'
import { loginAction } from 'reduxStore/identity/api'
// import { setSessionExpiration, setSessionIdle } from 'actions/session'
import { useDispatch, useSelector } from 'react-redux'
import WorldeskLogo from 'images/worldesk-white-new.svg'
import loginDecor from 'images/office-image.png'
import decorLogin from 'images/decor-login.png'
import SessionExpiredDialog from './SessionExpiredDialog'
// import EulaDialog2 from './../eula/EulaDialog2'
// import { notify } from 'actions/notifications'
// import getENV from '../../env'
import AuthFooter from 'components/baseComponents/Footer/AuthFooter'
import TextInput from 'components/baseComponents/Input/TextInput'
import { useDebounce } from 'use-debounce'

import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Link as ChakraLink,
  Text,
  FormErrorMessage,
  DarkMode,
} from '@chakra-ui/react'

const SignIn = () => {
  const titleColor = 'white'
  const textColor = 'gray.400'
  const dispatch = useDispatch()
  //   const { isAuthenticated, loading, recaptchaFail, error } = useSelector(
  //     (state: any) => state.identity
  //   )
  const { isAuthenticating, isAuthenticated, error } = useSelector(
    (state: any) => state.identity
  )
  //   const { isInit } = useSelector((state: any) => state.init)
  //   const { session_expired, session_idle } = useSelector(
  //     (state: any) => state.session
  //   )
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    isEmailValid: true,
    recaptcha: {
      token: '',
      version: '',
    },
  })
  const { email, password, isEmailValid } = formData
  const [debouncedFormData] = useDebounce(formData, 1000)
  const [openEula, setOpenEula] = useState(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const [recaptchaV2Success, setRecaptchaV2Success] = useState(false)

  //   useEffect(() => {
  //     dispatch(setLoading(false))
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [])

  //   useEffect(() => {
  //     if (recaptchaFail) {
  //       if (recaptchaFail === 'v3') {
  //         setDisableSubmitButton(true)
  //         renderV2Recaptcha()
  //       }
  //       if (recaptchaFail === 'v2') {
  //         dispatch(
  //           notify('Recaptcha Verification Failed, Refresh Page and Try Again')
  //         )
  //       }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [recaptchaFail])

  useEffect(() => {
    if (debouncedFormData.email) {
      if (validateEmail(debouncedFormData.email)) {
        setFormData({ ...formData, isEmailValid: true })
      } else {
        setFormData({ ...formData, isEmailValid: false })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormData.email])

  //   const V2Callback = (token: string) => {
  //     setFormData({
  //       ...formData,

  //       recaptcha: {
  //         ...formData.recaptcha,

  //         token,
  //         version: 'v2',
  //       },
  //     })
  //     setRecaptchaV2Success(true)
  //     setDisableSubmitButton(false)
  //   }

  //   const renderV2Recaptcha = () => {
  //     window.grecaptcha.ready(() => {
  //       window.grecaptcha.render('recaptch-v2-container', {
  //         sitekey: getV2Key(),
  //         theme: 'dark',
  //         callback: (token: string) => V2Callback(token),
  //       })
  //     })
  //   }

  const closeEulaDialog = () => {
    setOpenEula(false)
  }

  const openEulaDialog = (event: any) => {
    event.preventDefault()
    setOpenEula(true)
  }

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const validateForm = () => {
    let isValid = validateEmail(email)
    if (isValid) setFormData({ ...formData, isEmailValid: true })
    else setFormData({ ...formData, isEmailValid: false })
    return isValid
  }

  const onSubmit = (e?: any) => {
    e.preventDefault()
    //   const recaptcha = recaptchaData
    //     ? recaptchaData.recaptcha
    //     : formData.recaptcha
    if (validateForm() && password) {
      // dispatch(loginAction(email, password, recaptcha))
      dispatch<any>(loginAction(email, password))
    }
  }

  const handleClose = () => {
    // if (session_expired) dispatch(setSessionExpiration(false))
    // if (session_idle) dispatch(setSessionIdle(false))
  }

  if (isAuthenticated) {
    return (
      <Navigate
        replace
        to={{
          // @ts-ignore
          pathname: localStorage.getItem('last_path')
            ? localStorage.getItem('last_path')
            : '/',
          // @ts-ignore
          search: localStorage.getItem('last_search')
            ? localStorage.getItem('last_search')
            : '',
        }}
      />
    )
  }

  const getLogo = () => {
    return WorldeskLogo
  }

  const getPoweredBy = () => {
    return <Box />
    // if (getENV().APPLICATION_CLIENT_ID === 'ofx.web_application') {
    //   return (
    //     <Box mb='5px'>
    //       <Text textAlign='center'>Powered by OFX</Text>
    //     </Box>
    //   )
    // }
  }

  const onSubmitButtonClick = (e: any) => {
    onSubmit()
    //     e.preventDefault()
    //     if (!recaptchaV2Success) {
    //       window.grecaptcha.ready(function () {
    //         window.grecaptcha
    //           .execute(getV3Key(), { action: 'submit' })
    //           .then(function (token: string) {
    //             setFormData({
    //               ...formData,

    //               recaptcha: {
    //                 ...formData.recaptcha,

    //                 token,
    //                 version: 'v3',
    //               },
    //             })
    //             onSubmit({
    //               recaptcha: {
    //                 token,
    //                 version: 'v3',
    //               },
    //             })
    //           })
    //       })
    //     } else {
    //       onSubmit()
    //     }
  }

  const onBlur = (e: any) => {
    if (e.target.name === 'email') {
      if (validateEmail(e.target.value)) {
        setFormData({ ...formData, isEmailValid: true })
      } else {
        setFormData({ ...formData, isEmailValid: false })
      }
    }
  }

  //   if (isInit) {
  if (false) {
    return <LoadingScreen />
  }
  return (
    <DarkMode>
      <Flex position='relative' bgSize='cover'>
        <Flex
          minH='100vh'
          h={{ base: '100vh', lg: 'fit-content' }}
          w='100%'
          maxW='1044px'
          mx='auto'
          pt={{ sm: '100px', md: '0px' }}
          flexDirection='column'
          alignItems='center'
        >
          <Flex
            alignItems='flex-start'
            justifyContent='start'
            flexGrow='1'
            mt='100px'
            style={{ userSelect: 'none' }}
            w={{ sm: '300px', md: '450px' }}
          >
            <Flex direction='column' w='100%' bgColor='secondaryBg'>
              <Flex
                bgColor='brand.50'
                justifyContent='space-between'
                alignItems='flex-end'
              >
                <Box p='15px' fontSize='xs'>
                  <Box
                    bgImage={`url(${getLogo()})`}
                    bgSize='100% 100%'
                    opacity='1'
                    h='42px'
                    w='160px'
                  />
                  Backoffice
                </Box>
                <img src={loginDecor} width='150px' alt='' />
              </Flex>
              <Box p='20px 20px 6px'>
                <form onSubmit={e => onSubmit(e)}>
                  <FormControl isInvalid={!isEmailValid} mb='24px'>
                    <TextInput
                      isRequired
                      name='email'
                      value={email}
                      type='email'
                      onChange={e => onChange(e)}
                      onBlur={e => onBlur(e)}
                      // disabled={loading}
                      autoComplete='email'
                      isInvalid={!isEmailValid}
                      label='Email'
                      id='login-textfield-email'
                    />
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='normal'
                      color='white'
                    ></FormLabel>
                    {!isEmailValid && (
                      <FormErrorMessage id='invalid-email-feedback'>
                        {'Check the email address format'}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl mb='24px'>
                    <TextInput
                      isRequired
                      name='password'
                      value={password}
                      type='password'
                      onChange={e => onChange(e)}
                      onBlur={e => onBlur(e)}
                      // disabled={loading}
                      autoComplete='current-password'
                      label='Password'
                      id='login-textfield-password'
                    />
                  </FormControl>
                  {!isAuthenticating ? (
                    <FormControl isInvalid={error?.message}>
                      <FormErrorMessage
                        mb='15px'
                        mt='-8px'
                        id='error-response-message'
                      >
                        {error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  ) : null}
                  <Box mb='20px' id='recaptch-v2-container' />
                  <Button
                    //   onClick={onSubmitButtonClick}
                    variant='brand'
                    fontSize='sm'
                    type='submit'
                    w='100%'
                    height='buttonHeight'
                    mb='20px'
                    //   disabled={loading || disableSubmitButton}
                    fontWeight='normal'
                    id='login-submit'
                  >
                    {isAuthenticating ? 'LOGGING IN ...' : 'LOG IN'}
                  </Button>
                </form>
              </Box>
            </Flex>
          </Flex>
          <Box mb='15px'>
            <AuthFooter openEulaDialog={openEulaDialog} />
          </Box>
        </Flex>
        {/* <SessionExpiredDialog
          open={session_expired || session_idle}
          handleClose={handleClose}
          session_idle={session_idle}
        /> */}
      </Flex>
      {/* <RecaptchaText /> */}
    </DarkMode>
  )
}

export default SignIn
