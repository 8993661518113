export const currencies = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol: 'AED',
    decimal_digits: 2,
    flag: 'AE',
    countries: [
      {
        code: 'AE',
        name: 'United Arab Emirates',
      },
    ],
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'AU',
    countries: [
      {
        code: 'AU',
        name: 'Australia',
      },
    ],
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    symbol: 'лв',
    decimal_digits: 2,
    flag: 'BG',
    countries: [
      {
        code: 'BG',
        name: 'Bulgaria',
      },
    ],
  },
  {
    code: 'BHD',
    name: 'Bahrain Dinar',
    symbol: 'BD',
    decimal_digits: 2,
    flag: 'BH',
    countries: [
      {
        code: 'BH',
        name: 'Bahraini Dinar',
      },
    ],
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'CA',
    countries: [
      {
        code: 'CA',
        name: 'Canada',
      },
    ],
  },
  {
    code: 'COP',
    name: 'Colombian peso',
    symbol: 'COL$',
    decimal_digits: 2,
    flag: 'CO',
    countries: [
      {
        code: 'CO',
        name: 'Colombia',
      },
    ],
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan Renminbi',
    symbol: '¥',
    decimal_digits: 2,
    flag: 'CN',
    countries: [
      {
        code: 'CN',
        name: 'China',
      },
    ],
  },
  {
    code: 'CNH',
    name: 'Chinese Offshore Renminbi',
    symbol: '¥',
    decimal_digits: 2,
    flag: 'CN',
    countries: [
      {
        code: 'CN',
        name: 'China',
      },
    ],
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    symbol: 'CHF',
    decimal_digits: 2,
    flag: 'CH',
    countries: [
      {
        code: 'CH',
        name: 'Switzerland',
      },
    ],
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    symbol: 'Kč',
    decimal_digits: 2,
    flag: 'CZ',
    countries: [
      {
        code: 'CZ',
        name: 'Czech Republic',
      },
    ],
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    symbol: 'kr',
    decimal_digits: 2,
    flag: 'DK',
    countries: [
      {
        code: 'DK',
        name: 'Denmark',
      },
    ],
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    decimal_digits: 2,
    flag: 'EU',
    countries: [
      {
        code: 'AD',
        name: 'Andorra',
      },
      {
        code: 'AT',
        name: 'Austria',
      },
      {
        code: 'BE',
        name: 'Belgium',
      },
      {
        code: 'CY',
        name: 'Cyprus',
      },
      {
        code: 'EE',
        name: 'Estonia',
      },
      {
        code: 'FI',
        name: 'Finland',
      },
      {
        code: 'FR',
        name: 'France',
      },
      {
        code: 'DE',
        name: 'Germany',
      },
      {
        code: 'GR',
        name: 'Greece',
      },
      {
        code: 'IE',
        name: 'Ireland',
      },
      {
        code: 'IT',
        name: 'Italy',
      },
      {
        code: 'LV',
        name: 'Latvia',
      },
      {
        code: 'LT',
        name: 'Lithuania',
      },
      {
        code: 'LU',
        name: 'Luxembourg',
      },
      {
        code: 'MT',
        name: 'Malta',
      },
      {
        code: 'MC',
        name: 'Monaco',
      },
      {
        code: 'ME',
        name: 'Montenegro',
      },
      {
        code: 'NL',
        name: 'Netherlands',
      },
      {
        code: 'PT',
        name: 'Portugal',
      },
      {
        code: 'SM',
        name: 'San Marino',
      },
      {
        code: 'SK',
        name: 'Slovakia',
      },
      {
        code: 'SI',
        name: 'Slovenia',
      },
      {
        code: 'ES',
        name: 'Spain',
      },
    ],
  },
  {
    code: 'GBP',
    name: 'British Pound',
    symbol: '£',
    decimal_digits: 2,
    flag: 'GB',
    countries: [
      {
        code: 'GB',
        name: 'United Kingdom',
      },
    ],
  },
  {
    code: 'GHS',
    name: 'Ghanaian cedi',
    symbol: 'GH₵',
    decimal_digits: 2,
    flag: 'GH',
    countries: [
      {
        code: 'GH',
        name: 'Ghana',
      },
    ],
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'HK',
    countries: [
      {
        code: 'HK',
        name: 'Hong Kong',
      },
    ],
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    symbol: 'kn',
    decimal_digits: 2,
    flag: 'HR',
    countries: [
      {
        code: 'HR',
        name: 'Croatia',
      },
    ],
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    symbol: 'Ft',
    decimal_digits: 2,
    flag: 'HU',
    countries: [
      {
        code: 'HU',
        name: 'Hungary',
      },
    ],
  },
  {
    code: 'ILS',
    name: 'Israeli New Sheqel',
    symbol: '₪',
    decimal_digits: 2,
    flag: 'IL',
    countries: [
      {
        code: 'IL',
        name: 'Israel',
      },
    ],
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    symbol: '₹',
    decimal_digits: 2,
    flag: 'IN',
    countries: [
      {
        code: 'IN',
        name: 'India',
      },
    ],
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    symbol: 'د.ا',
    decimal_digits: 2,
    flag: 'JO',
    countries: [
      {
        code: 'JO',
        name: 'Jordan',
      },
    ],
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    symbol: '¥',
    decimal_digits: 0,
    flag: 'JP',
    countries: [
      {
        code: 'JP',
        name: 'Japan',
      },
    ],
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    symbol: 'Ksh',
    decimal_digits: 2,
    flag: 'KE',
    countries: [
      {
        code: 'KE',
        name: 'Kenya',
      },
    ],
  },
  {
    code: 'KRW',
    name: 'South Korean won',
    symbol: '₩',
    decimal_digits: 2,
    flag: 'KR',
    countries: [
      {
        code: 'KR',
        name: 'South Korean',
      },
    ],
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    symbol: 'KD',
    decimal_digits: 2,
    flag: 'KS',
    countries: [
      {
        code: 'KS',
        name: 'Kuwait',
      },
    ],
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    symbol: 'DH',
    decimal_digits: 2,
    flag: 'MA',
    countries: [
      {
        code: 'MA',
        name: 'Morocco',
      },
    ],
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: '$',
    decimal_digits: 2,
    flag: 'MX',
    countries: [
      {
        code: 'MX',
        name: 'Mexico',
      },
    ],
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    symbol: 'kr',
    decimal_digits: 2,
    flag: 'NO',
    countries: [
      {
        code: 'NO',
        name: 'Norway',
      },
    ],
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'NZ',
    countries: [
      {
        code: 'NZ',
        name: 'New Zealand',
      },
    ],
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    symbol: '﷼',
    decimal_digits: 2,
    flag: 'OM',
    countries: [
      {
        code: 'OM',
        name: 'Oman',
      },
    ],
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    symbol: 'zł',
    decimal_digits: 2,
    flag: 'PL',
    countries: [
      {
        code: 'PL',
        name: 'Poland',
      },
    ],
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
    symbol: '﷼',
    decimal_digits: 2,
    flag: 'QA',
    countries: [
      {
        code: 'QA',
        name: 'Qatar',
      },
    ],
  },
  {
    code: 'RON',
    name: 'Romanian New Leu',
    symbol: 'lei',
    decimal_digits: 2,
    flag: 'RO',
    countries: [
      {
        code: 'RO',
        name: 'Romania',
      },
    ],
  },
  {
    code: 'RUB',
    name: 'Russian Rouble',
    symbol: 'руб',
    decimal_digits: 2,
    flag: 'RU',
    countries: [
      {
        code: 'RU',
        name: 'Russia',
      },
    ],
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    symbol: '﷼',
    decimal_digits: 2,
    flag: 'SA',
    countries: [
      {
        code: 'SA',
        name: 'Saudi Arabia',
      },
    ],
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    symbol: 'kr',
    decimal_digits: 2,
    flag: 'SE',
    countries: [
      {
        code: 'SE',
        name: 'Sweden',
      },
    ],
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'SG',
    countries: [
      {
        code: 'SG',
        name: 'Singapore',
      },
    ],
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    symbol: '฿',
    decimal_digits: 2,
    flag: 'TH',
    countries: [
      {
        code: 'TH',
        name: 'Thailand',
      },
    ],
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    symbol: 'DT',
    decimal_digits: 2,
    flag: 'TN',
    countries: [
      {
        code: 'TN',
        name: 'Tunisia',
      },
    ],
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    symbol: '₺',
    decimal_digits: 2,
    flag: 'TR',
    countries: [
      {
        code: 'TR',
        name: 'Turkey',
      },
    ],
  },
  {
    code: 'USD',
    name: 'United States Dollar',
    symbol: '$',
    decimal_digits: 2,
    flag: 'US',
    countries: [
      {
        code: 'US',
        name: 'United States',
      },
    ],
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    symbol: 'R',
    decimal_digits: 2,
    flag: 'ZA',
    countries: [
      {
        code: 'ZA',
        name: 'South Africa',
      },
    ],
  },
  {
    code: 'GEL',
    name: 'Georgian lari',
    symbol: '₾',
    decimal_digits: 2,
    flag: 'GE',
    countries: [
      {
        code: 'GE',
        name: 'Georgia',
      },
    ],
  },
]

export const getCurrencySymbol = (code: string) => {
  let c = currencies.find(c => c.code === code)
  if (c) return `${c.symbol}`
  else return ''
}
