import {
  Box,
  chakra,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOverviewTransationsAction } from 'reduxStore/overview/api'
import moment from 'moment'
import { displayDateFormat } from 'utils/dateFormat'
import AmountCell from 'components/baseComponents/Tables/AmountCell'
import { formatNumber } from 'utils/numberFormat'
import LoadingScreen from 'components/baseComponents/loading/LoadingScreen'
import Totals from './Totals'
import PeriodSelect from './PeriodSelect'

const formatDate = (dt: string) => {
  return moment(dt).format(displayDateFormat())
}

function Dashboard() {
  const RouterChakraLink = chakra(Link)
  const applicationTitle = document.title
  document.title = `Dashboard | ${applicationTitle}`
  const dispatch = useDispatch()
  const [period, setPeriod] = useState(12)
  const { transactions } = useSelector((state: any) => state.overview)
  useEffect(() => {
    dispatch<any>(getOverviewTransationsAction(period))
  }, [period, dispatch])

  const getPayments = () => {
    if (transactions.payments.records.length) {
      return (
        <Box>
          <Box padding='20px' color='lightGrayColor'>
            <Box color='whiteAlpha.900'>Payments</Box>
          </Box>
          <TableContainer
            color='tableTextColor'
            flexGrow='1'
            className='scrollbar'
          >
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Created</Th>
                  <Th>Provider</Th>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>State</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.payments.records.map((item: any) => {
                  return (
                    <Tr key={item.id} bgColor={'transparent'}>
                      <Td>{formatDate(item.created_at)}</Td>
                      <Td>{item.currency_provider}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        <AmountCell
                          amount={formatNumber(item.amount)}
                          currency={item.currency}
                        />
                      </Td>
                      <Td>{item.state}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )
    }
  }

  const getConversions = () => {
    if (transactions.conversions.records.length) {
      return (
        <Box>
          <Box padding='20px' color='lightGrayColor'>
            <Box color='whiteAlpha.900'>Conversions</Box>
          </Box>
          <TableContainer
            color='tableTextColor'
            flexGrow='1'
            className='scrollbar'
          >
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Created</Th>
                  <Th>Provider</Th>
                  <Th>Name</Th>
                  <Th>Rate</Th>
                  <Th>Sell Amount</Th>
                  <Th>Buy Amount</Th>
                  <Th>State</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.conversions.records.map((item: any) => {
                  return (
                    <RouterChakraLink
                      key={item.id}
                      display='contents'
                      to={`/companies/${item.id}`}
                    >
                      <Tr _hover={{ bg: 'gray.700' }}>
                        <Td>{formatDate(item.created_at)}</Td>
                        <Td>{item.currency_provider}</Td>
                        <Td>{item.name}</Td>
                        <Td>
                          {item.fixed_side === 'buy' ? (
                            <>
                              <AmountCell
                                currency={item.buy_currency}
                                amount={'1.00'}
                              />
                              =
                              <AmountCell
                                currency={item.sell_currency}
                                amount={item.rate ? item.rate.toString() : ''}
                              />
                            </>
                          ) : (
                            <>
                              <AmountCell
                                currency={item.sell_currency}
                                amount={'1.00'}
                              />
                              =
                              <AmountCell
                                currency={item.buy_currency}
                                amount={item.rate ? item.rate.toString() : ''}
                              />
                            </>
                          )}
                        </Td>
                        <Td>
                          {item.fixed_side === 'sell' ? (
                            <AmountCell
                              amount={formatNumber(item.amount)}
                              currency={item.sell_currency}
                            />
                          ) : (
                            <AmountCell
                              amount={formatNumber(item.funded_amount)}
                              currency={item.sell_currency}
                            />
                          )}
                        </Td>
                        <Td>
                          {item.fixed_side === 'sell'
                            ? `${formatNumber(item.funded_amount)} ${
                                item.buy_currency
                              }`
                            : `${formatNumber(item.amount)} ${
                                item.buy_currency
                              }`}
                        </Td>
                        <Td>{item.state}</Td>
                      </Tr>
                    </RouterChakraLink>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )
    }
  }
  const getLockRates = () => {
    if (transactions.lock_rates.records.length) {
      return (
        <Box>
          <Box padding='20px' color='lightGrayColor'>
            <Box color='whiteAlpha.900'>Lock Rates</Box>
          </Box>
          <TableContainer
            color='tableTextColor'
            flexGrow='1'
            className='scrollbar'
          >
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Mature</Th>
                  <Th>Provider</Th>
                  <Th>Name</Th>
                  <Th>Rate</Th>
                  <Th>Sell Amount</Th>
                  <Th>Buy Amount</Th>
                  <Th>State</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.lock_rates.records.map((item: any) => {
                  return (
                    <Tr _hover={{ bg: 'gray.700' }} key={item.id}>
                      <Td>{formatDate(item.scheduled_at)}</Td>
                      <Td>{item.currency_provider}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        {item.fixed_side === 'buy' ? (
                          <>
                            <AmountCell
                              currency={item.buy_currency}
                              amount={'1.00'}
                            />
                            =
                            <AmountCell
                              currency={item.sell_currency}
                              amount={item.rate ? item.rate.toString() : ''}
                            />
                          </>
                        ) : (
                          <>
                            <AmountCell
                              currency={item.sell_currency}
                              amount={'1.00'}
                            />
                            =
                            <AmountCell
                              currency={item.buy_currency}
                              amount={item.rate ? item.rate.toString() : ''}
                            />
                          </>
                        )}
                      </Td>
                      <Td>
                        {item.fixed_side === 'sell' ? (
                          <AmountCell
                            amount={formatNumber(item.amount)}
                            currency={item.sell_currency}
                          />
                        ) : (
                          <AmountCell
                            amount={formatNumber(item.funded_amount)}
                            currency={item.sell_currency}
                          />
                        )}
                      </Td>
                      <Td>
                        {item.fixed_side === 'sell'
                          ? `${formatNumber(item.funded_amount)} ${
                              item.buy_currency
                            }`
                          : `${formatNumber(item.amount)} ${item.buy_currency}`}
                      </Td>
                      <Td>{item.state}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )
    }
  }

  const getTransactions = () => {
    if (transactions.isFetched && !transactions.isFailed) {
      return (
        <>
          <Totals period={period} />
          {getPayments()}
          {getConversions()}
          {getLockRates()}
        </>
      )
    }
    if (transactions.isFailed) {
      return <Box m='20px'>Failed to load transactions</Box>
    }
    return <LoadingScreen />
  }

  return (
    <Box>
      <Flex justifyContent='flex-end'>
        <PeriodSelect
          period={period}
          onChange={(value: number) => setPeriod(value)}
        />
      </Flex>
      {getTransactions()}
    </Box>
  )
}

export default Dashboard
